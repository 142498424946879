import React, { useState } from "react";

import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import { appState } from "../AppState";
import { Button, Modal } from "react-bootstrap";
import NewLogo2 from "./../images/pgw-main-logo.png";
import LoginComponent from "./FBButton"
import Cookies from 'universal-cookie';
import GameService from "../services/service";

const FacebookScreen = (props) => {
  const [modal, setModal] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [savings, setSaving] = useState(false);
  const toggle = () => setModal(!modal);
  const [email, setEmail] = useState("");
  const [stateApp, stateAppActions] = appState();
  let history = useHistory();
  const cookies = new Cookies();

  
  const submitEmail = async (e) => {
    e.preventDefault();
    stateAppActions.setEmail(email);
    cookies.set('email', email, { path: '/' });
    stateAppActions.setGameStart(true);
   
    setSaving(true);
    await GameService.impression({type: 'email', user: email, event: 'login', data: '{}'})
    setSaving(false);
    history.push("/level/1");
    
  };
  
  const toggleEmail = (props) => {
    setShowEmail(!showEmail);
    console.log("here");
  };
  return (
    <Modal show={true}>
      <Modal.Header style={{ justifyContent: "center" }}>
        <img style={{ width: 240 + "px" }} src={NewLogo2}></img>
        {/* <Modal.Title>Sign in to Continue</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="text-center">
        {" "}
        {!showEmail && (
          <div>
         <LoginComponent cookies={cookies}></LoginComponent>
          
            <hr></hr>
            <button
              style={{ minWidth: 300 + "px", backgroundColor: "#2a982a" }}
              onClick={() => toggleEmail()}
              type="button"
              class="email-button text-left"
            >
              <i style={{minWidth: 26 + 'px'}} className="far fa-envelope"></i> Login with Email
            </button>
          </div>
        )}
        {showEmail && (
          <div>
            <div>
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="form-control pb-3"
                placeholder="E-Mail Address"
              ></input>
              <br></br>
              <button
                disabled={props.active}
                type="button"
                className="btn btn-raised btn-lg btn-danger"
                style={{ backgroundColor: "" }}
                onClick={() => toggleEmail()}
              >
                Back
              </button>{" "}
              <button
                disabled={props.active}
                type="button"
                className="btn btn-raised btn-lg btn-success"
                style={{ backgroundColor: "" }}
                onClick={(e) => submitEmail(e)}
              >
                Play
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default FacebookScreen;
