import React from "react";
import { useHistory } from "react-router-dom";
import { appState } from "../../AppState";
const Menu = props =>{
    const [stateApp, stateAppActions] = appState();
    let history = useHistory();

    function startGame() {
        stateAppActions.setGameStart(true);
      history.push("/level/1");
    }
    function leaderBoard() {
        stateAppActions.setGameStart(true);
      history.push("/leaderboard");
    }

    return(
<>
     <div className="row h-100 text-center">
         <div className="col-lg-12 col-sm-12 ">
             <button className="btn btn-raised btn-lg start-button btn-success" onClick={() => startGame()}>
                 Start
             </button>
           
         </div>
         
         <div className="col-lg-12 col-sm-12 mt-2">
           
             <a href="/leaderboard"><button type="button" className="btn btn-raised  btn-lg start-button mt-3 btn-warning" >Leaderboard</button></a>
         </div>
     </div>
    
     </>
    )
}

export default Menu;
