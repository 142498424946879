import React from "react"
import PlainHeader from "../PlainHeader"


const Article10 = props =>{


    return (
        <div><PlainHeader></PlainHeader><h1>Global protest against the removal of the statue of a slave merchant who was assassinated by George Floyd Washington:</h1>
        <p>&nbsp;Protests are being held around the world to protest the killing of a black man, George Floyd, who was killed by the police. At the same time, a campaign to cut police funding has been launched in the United States. According to "Express News", protests against racial discrimination and police violence took place in the UK, Australia, Germany, Spain and France. A young black man arrested during a protest in the United States died in police custody. Rally was held in various cities in the United States to protest against the murder of George Floyd by white police officers. On Friday, police in riot gear burst into a rally and used trucks to disperse hundreds of protesters. Clashes broke out in New York, Chicago, Philadelphia and Buffalo. The National Guard was also sent home from Washington. "Is the police court over?" The local council in Minneapolis is being assassinated by George Floyd. The city council is considering closing the police station under pressure from protesters. The mayor of New York announced a reduction in police funding. After the police officer assassinated George Floyd (George Floyd), people called for the reduction of police funding in US states and cities. According to data compiled by the Ur.S. Urban Institute in 2017, state and local governments spend $11,115 billion on public security each year. Some experts believe that the number of police officers in many states does not match the population. The statue of the slave merchant was demolished In protests against racial hatred, protesters in Bristol, England removed the statue of Edward Colston and will ride on his neck. Colston was a wealthy seventeenth century businessman. He was one of the key figures in the UK&rsquo;s only Royal African Company, which enslaved and traded blacks. The company is headquartered in Bristol. The company sold thousands of black people working in the agricultural sector.</p></div>
    )
}

export default Article10