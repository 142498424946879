import React from "react"
import PlainHeader from "../PlainHeader"


const Article11= props =>{


    return (
        <div><PlainHeader></PlainHeader><h1>The Indian government is puzzled by the referendum in Kalistan</h1>
        <p>Sikhs around the world are registering for the 20th century referendum held in their respective country, Khalistan, and the
Indian government, on the other hand, seems to be confused by the referendum. .
Canadian Sikh justice chief Gurpattant Singh Pinnu said in an interview with "Express" that Sikhs will oust the Indian capital
Delhi through this peace referendum.
Gurpant Singh has asked Sikhs living in Delhi to pray in Gurdwara Sheesh Ganj and Gurudwara Bangla Sahib and register
them on the 20/20 referendum list. I was barred from participating in a referendum. Yesterday, the Mian Mir Foundation in
Delhi also organized a protest in front of the UN office to protest the referendum in Khalistan. The source said that the
protest was organized at the request of Indian intelligence agencies.
Sikh leaders said that millions of Sikhs around the world, including Pakistan and India, have registered online, and the
process is continuing. Indian politicians, police and media have adopted clever tactics to call for peace campaign terrorism,
which is condemned. He wanted to make it clear to the Sikhs in India that the Delhi government is not friendly to them. So
far, the Indian Constitution has not recognized Sikhs and regarded them as part of Hindus. The country has forgotten these
atrocities and will not forget them.
At the same time, Sardar Satwant Singh, chairman of the Gudwara Prabandak Committee of Pakistani Sikhs, said that Sikhs
need an independent identity and an independent country. The history of the Sikh nation is full of sacrifices. The Sikh state
of Pakistan supports the Khalistan movement and will continue to do so. .
It should be noted that the Sikh community in Pakistan is also preparing for the 20-20 referendum.</p>
        </div>
    )
}
export default Article11