import React from "react";
import Menu from "../core/Menu"
import Header from "../header/header"

const Game = (props) => {
  return (
    <>
    <div id="menu-page">
     <Header></Header>
      <div className="container-fluid">
        <Menu></Menu>
      </div>
      
    </div>
    </>
  );
};

export default Game;
