import React from "react";

import LivesIcon from "../../images/usa-flag-icon.png";
import { appState } from "../../AppState";
import NewLogo2 from "../../images/pgw-level-logo.png";
import Timer from "./Timer";
const ScoreHeader = (props) => {
  const [stateApp, stateAppActions] = appState();

  return (
    <div className="container-fluid">
      
      <div className="row level-header">
        <div className="col-5">
          <span className="level-title">
            <a href="/start"><img src={NewLogo2} style={{ width: 65 + "px" }}></img></a>
          </span>
        </div>
        <div className="col-7 text-right d-none">
          <span className="level-title">Lives:</span>
          {Array(stateApp.lives)
            .fill()
            .map((e, i) => {
              return (
                <span key={i}>
                  {stateApp.currentLives <= i ? (
                    <i
                      class="fa fa-times decrease-life animate__rotateIn animate__animated"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <></>
                  )}
                  <img key={i} className="lives-icon" src={LivesIcon} />
                </span>
              );
            })}
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center pt-3">
          <h4 className="left">
            <span className="badge badge-pill badge-primary">
              Level {stateApp.level}
            </span>
          </h4>

          {/* <h5>Title: {stateApp.titles[stateApp.level - 1]}</h5> */}
          {/* <div className="right">
                <small style={{float: 'left'}}>Score {stateApp.round}/50</small>  
                <Timer></Timer>
            </div> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <small style={{ float: "left" }}>
                Score <span className="text-success">{stateApp.score}</span>/{stateApp.totalRounds}
              </small>{" "}
            </div>
            <div>Round {stateApp.round > stateApp.totalRounds ? stateApp.totalRounds : stateApp.round}</div>
            <div>
              {" "}
              <Timer round={stateApp.round}></Timer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreHeader;
