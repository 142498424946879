import React from "react"
import PlainHeader from "../PlainHeader"


const Article16= props =>{


    return (
        <div><PlainHeader></PlainHeader><h1>The Emirati people are not with the government in the peace agreement with Israel, shocking revelations</h1>
        <p>Ahmed al-Sheiba, a leading UAE intellectual and an activist in the movement to normalize relations with Israel, has said that the UAE people do not support Abu Dhabi's peace deal with Israel. If the Emirati people had the right to free speech, you would see thousands of people taking to the streets to oppose the agreement with Israel. The agreement has not been accepted. As far as these videos Relationships that seemingly try to give the impression that the Emirati people are celebrating friendship with Israel are fake. The Emirati people are not only opposed to this agreement, but they are truly shocked. Al-Sheiba said those celebrating the friendship with Israel are either mercenaries who have taken part in such activities for money or are privileged by the current ruling class. A handful of such elements feel no shame in selling their conscience at the expense of basic principles and human values. In the interview, he said that the government is trying to make the declaration of relations with Israel popular, but in reality the Emirati people are Israel. Are not in favor of a deal with So far, no such event has been convened by the Supreme Federal Council or any other body to which the people have been invited. Raising awareness and reminding the people of the Gulf countries of their responsibilities regarding the Palestinian cause. He said that relations between the UAE and Israel have been going on for the last 20 years. The recent agreement between the two countries is the result of many years of contacts</p></div>
    )
}

export default Article16