

import React, { useState, useEffect } from 'react';
import { appState } from "../../AppState";
const Timer = props => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [stateApp, stateAppActions] = appState();
  let interval = null;

  function toggle() {
    setIsActive(!isActive);
  }

  function reset() {
    setSeconds(0);
    setIsActive(false);
  }

  useEffect(() => {
   
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      
      clearInterval(interval);
    }
    console.log(props.round)
    if (props.round == (stateApp.totalRounds + 1)) {
      stateAppActions.updateTime(seconds);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds]);
  
 

   
  return (
    
      <div className="time">
        <small><i className='fas fa-stopwatch' ></i> {seconds}s</small>
      </div>
     
 
  );
};

export default Timer;
