import React from "react";

import LivesIcon from "../../images/usa-flag-icon.png";
import { appState } from "../../AppState";
import NewLogo2 from "../../images/pgw-level-logo.png";
import Timer from "./Timer";
const PlainHeader = (props) => {
  const [stateApp, stateAppActions] = appState();

  return (
    <div className="container-fluid">
      <div className="row level-header">
        <div className="col-5">
          <span className="level-title">
            <a href="/start"><img src={NewLogo2} style={{ width: 65 + "px" }}></img></a>
          </span>
        </div>
       
      </div>
   
    </div>
  );
};

export default PlainHeader;
