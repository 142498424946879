import React from "react"
import PlainHeader from "../PlainHeader"


const Article15 = props =>{


    return (
     <div><PlainHeader></PlainHeader><h1>Big blow to US 2 Islamic countries refuse to recognize Israel</h1>
     <p>&nbsp;| Tilt the Saudi Crown Prince toward Israel Two Islamic countries, Morocco and Sudan, have refused to recognize Israel. According to the Russian news agency, Sudanese Prime Minister Abdullah Hamduk has refused to establish diplomatic relations with Israel, saying his interim government has relations with Israel He called on the US administration to set aside the issue of normalizing relations with the terroristsponsoring states of Sudan and Israel. The interim government in Sudan is working on a limited agenda He said the aim was to restore peace and stability in the country, launch free elections and other necessary measures. Morocco's Prime Minister Saad al-Othmani, on the other hand, said that Morocco was strongly opposed to the normalization of diplomatic relations with the Israeli government. Is. The normalization of relations with Israel will increase Israeli oppression of the Palestinians and allow Israel to occupy more Arab territories. Saudi Crown Prince Mohammed bin Salman has canceled a meeting with Israeli Prime Minister Netanyahu, a meeting planned to be kept secret, which was limited to a handshake. It was planned to release the video of Muhammad bin Salman's meeting after his return from the United States, but after the plan came to light, Saudi Crown Prince Muhammad bin Salman apologized for the meeting. Saudi Foreign Minister Prince Faisal bin Farhan had said that no meeting could be planned between the Crown Prince and the Prime Minister of Israel. Saudi Arabia's policy towards Israel is very clear.</p></div>   
    )
}

export default Article15