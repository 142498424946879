import React from "react";
import globalHook from "./UseGlobalHook";
import { constSelector } from "recoil";

const initialState = {
  totalRounds: 25,
  lives: 5,
  gameStarted: false,
  facebookInfo: null,
  currentLives: 5,
  consecutiveWrong: 0,
  consecutiveCorrect: 0,
  score: 0,
  level: 1,
  round: 1,
  time: 0,
  picIndex: 0,
  titles: [
    "Alderman 🧍",
    "Govenor 🤵",
    "US Senator 🎩",
    "Speaker of The House 🎤",
    "Vice President 🦧",
    "President 🕺",
  ],
  levelInstructions: { 1: "Get 5 wrong in a row you loose a life " },
  email: null,
  leaderboard: [],
};

const stateActions = {
  startNewGame: (store, newGame) => {
    store.setState({
      lives: 5,
      currentLives: 5,
      consecutiveWrong: 0,
      consecutiveCorrect: 0,
      score: 0,
      level: 1,
      round: 1,
      time: 0,
      picIndex: 0,
    });
  },
  setLeaderboard: (store, leaderboard) => {
    store.setState({ leaderboard: leaderboard });
  },
  setGameStart: (store, gameStarted) => {
    store.setState({ gameStarted: gameStarted });
  },
  updateTime: (store, time) => {
    store.setState({ time: time });
  },
  setFacebook: (store, facebookInfo) => {
    store.setState({ facebookInfo: facebookInfo });
  },
  setEmail: (store, email) => {
    store.setState({ email: email });
  },
  setCurrentLives: (store, newLives) => {
    store.setState({ currentLives: newLives });
  },
  setConsecutiveWrong: (store, wrongTimes) => {
    if (wrongTimes != 0 && wrongTimes % 3 == 0) {
      console.log(store.state.consecutiveWrong % 3);
      store.setState({ currentLives: store.state.currentLives - 1 });
    }
    store.setState({ consecutiveWrong: wrongTimes });
    console.log(store.state);
  },
  setConsecutiveCorrect: (store, correctTimes) => {
    store.setState({ consecutiveCorrect: correctTimes });
  },
  setScore: (store, newScore) => {
    store.setState({ score: newScore });
  },
  setLevel: (store, newLevel) => {
    store.setState({ level: newLevel });
  },
  setRound: (store, newRounds) => {
    switch (newRounds) {
      case 10:
        store.setState({ level: 2 });
        break;
      case 20:
        store.setState({ level: 3 });
        break;
      case 30:
        store.setState({ level: 3 });
        break;
      case 40:
        store.setState({ level: 4 });
        break;
      case 50:
        store.setState({ level: 5 });
        break;
      default:
        break;
    }
    store.setState({ round: newRounds });
  },
  hasHighscore: (store, score) => {
    
    for (var i = 0; i < store.state.leaderboard.length; i++) {
      if (
        score.seconds < store.state.leaderboard[i].seconds &&
        score.score < store.state.leaderboard[i].score
      )
        return true;
    }
    return false;
  },
  setPicIndex: (store, newPicIndex) => {
    store.setState({ picIndex: newPicIndex });
  },
};

export const appState = globalHook(React, initialState, stateActions);
