import React from "react";
import { Button, Modal } from 'react-bootstrap';
import {  useState } from "react";
import { appState } from "../../AppState";

const LevelModal = props =>{
    const [show, setShow] = useState(props.show ?? false);
    const [stateApp, stateAppActions] = appState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Instructions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Guess each person's political affiliation by selecting either the Democrat (Donkey) or Republican (Elephant) icon. </p>
          <p>There will be a total of {stateApp.totalRounds} people to guess from and you will score a point for each one you correctly guess.</p>
          <p>Share Your Score on Facebook and Challenge Your Friends!</p>
          <p></p>
          
          </Modal.Body>
          <Modal.Footer>
         
            <Button variant="secondary" onClick={handleClose}>
              Ok
            </Button>
          </Modal.Footer>

       
        </Modal>
      </>
    );
  }
  
  export default LevelModal