import React from "react";
import Fired from "../../images/trump-fired.jpg";
import Confetti from "./Confetti";
import { appState } from "../../AppState";
import { useHistory } from "react-router-dom";
import moment from "moment"
import momentDurationFormatSetup  from "moment-duration-format";
import { FacebookShareButton, FacebookIcon,FacebookShareCount,FacebookMessengerIcon,FacebookMessengerShareButton, TwitterShareButton, TwitterIcon } from "react-share";
import LeaderboardEntry from "./LeadereboardEntry";
const FinishScreen = (props) => { 
    momentDurationFormatSetup(moment);
    const [stateApp, stateAppActions] = appState();
    let history = useHistory();
    const shareUrl = 'https://www.politicalguesswho.com/start';
    const title = `Try to beat me in PGW! Score: ${stateApp.score} out of ${stateApp.totalRounds} | Time: ${moment.duration(parseFloat(stateApp.time), "seconds").format("m [minutes], s [seconds]")}`
    const startOver = () =>{
    window.location.href = "/start"
    }
  return (
    <div>
      <Confetti></Confetti>
      {stateAppActions.hasHighscore ? <LeaderboardEntry></LeaderboardEntry> : <></>}
      <div className="text-center">
        <br></br>
  <h1>Congrats!</h1> 
        <h3>You scored {stateApp.score} out of {stateApp.totalRounds} correctly! Share your results to Facebook and challenge your friends!</h3>
        <div>
            <br></br>
            <h2>Share Your Score</h2>
          <div className="Demo__container">
            <div className="Demo__some-network">
              <FacebookShareButton
                url={shareUrl}
                quote={title}
                className="Demo__some-network__share-button"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <div>
                <FacebookShareCount
                  url={shareUrl}
                  className="Demo__some-network__share-count"
                >
                  {(count) => count}
                </FacebookShareCount>
              </div>
            </div>

            <div className="Demo__some-network">
              <FacebookMessengerShareButton
                url={shareUrl}
                appId="521270401588372"
                className="Demo__some-network__share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>
            </div>

            <div className="Demo__some-network">
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className="Demo__some-network__share-button"
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <div className="Demo__some-network__share-count">&nbsp;</div>
            </div>
            
          </div>
        </div>
        <button className="btn btn-raised btn-lg " onClick={() => startOver()}>Play Again!</button><br></br>
        <button className="btn btn-raised btn-lg "onClick={() => history.push("/leaderboard")}>View Leaderboard</button>
      </div>
    </div>
  );
};
export default FinishScreen;
