import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import GameProtector from "./Utility/GameProtector"

import {
  Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import Level from "./components/core/Level";
import {  useState } from "react";
import useAsyncEffect from "use-async-effect";
import Game from "./components/core/Game";
import { createBrowserHistory } from "history";
import { BrowserView, MobileView } from "react-device-detect";
import FacebookScreen from "./Utility/FacebookLogin";
import Leaderboard from "./components/core/Leadboard";
import Cookies from "universal-cookie";
import GameService from "./services/service"
import { appState } from "./AppState";
import Blog from "./components/core/blog";
import PrivacyPolicy from "./components/core/PrivacyPolicy";
import About from "./components/core/About";
import Article21 from "./components/core/blog/Article21";
import Article20 from "./components/core/blog/Article20";
import Article19 from "./components/core/blog/Article19";
import Article18 from "./components/core/blog/Article18";
import Article17 from "./components/core/blog/Article17";
import Article16 from "./components/core/blog/Article16";
import Article15 from "./components/core/blog/Article15";
import Article14 from "./components/core/blog/Article14";
import Article13 from "./components/core/blog/Article13";
import Article12 from "./components/core/blog/Article12";
import Article11 from "./components/core/blog/Article11";
import Article10 from "./components/core/blog/Article10"
import Article9 from "./components/core/blog/Article9";
export const appHistory = createBrowserHistory();



const App = (props) => {
  const [stateApp, stateAppActions] = appState();
  useAsyncEffect(async (isMounted) => {
    const result = await GameService.get_leaderboard();
    
    if (!isMounted()) return;
    stateAppActions.setLeaderboard(result.data)
   
  }, []);

  useEffect(() => {
  
    const cookies = new Cookies();
    let email = cookies.get('email')
    let facebook = cookies.get('facebook')
    if(email)stateAppActions.setEmail(email);
    if(facebook)stateAppActions.setFacebook(facebook);

    
  }, []);
  

  return (
    <>

      <div style={{ height: 100 + "vh" }} >
        <Router history={appHistory}>
          <Switch>
            <Route
              exact
              path="/level/:id"
              render={(routeProps) => <GameProtector><Level {...props} {...routeProps} /></GameProtector>}
            ></Route>
            <Route
              path="/leaderboard"
              render={(routeProps) => (
                <Leaderboard {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
             <Route
              path="/login"
              render={(routeProps) => (
                <FacebookScreen {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
            <Route
              path="/start"
              render={(routeProps) => (
                <Game {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
             <Route
              path="/blog"
              render={(routeProps) => (
                <Blog {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
             <Route
              path="/privacy-policy"
              render={(routeProps) => (
                <PrivacyPolicy {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
            <Route
              path="/about-us"
              render={(routeProps) => (
                <About {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
            <Route
              path="/article21"
              render={(routeProps) => (
                <Article21 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article20"
              render={(routeProps) => (
                <Article20 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article19"
              render={(routeProps) => (
                <Article19 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article18"
              render={(routeProps) => (
                <Article18 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article17"
              render={(routeProps) => (
                <Article17 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article16"
              render={(routeProps) => (
                <Article16 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article15"
              render={(routeProps) => (
                <Article15 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article14"
              render={(routeProps) => (
                <Article14 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article13"
              render={(routeProps) => (
                <Article13 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article12"
              render={(routeProps) => (
                <Article12 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article11"
              render={(routeProps) => (
                <Article11 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article10"
              render={(routeProps) => (
                <Article10 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
              <Route
              path="/article9"
              render={(routeProps) => (
                <Article9 {...routeProps} {...props} {...routeProps} />
              )}
            ></Route>
            <Redirect to="/start" />
          </Switch>
        </Router>
      </div>
    
    </>
  );
};

export default App;
