import axios from "axios";



const GameService = {
    
    get_levels: function(params ={}) {
      let request = axios.get(
          'https://rm6p9rl88e.execute-api.us-east-1.amazonaws.com/dev/levels',
          params
          );
        return request;
    },
    get_leaderboard: function(params ={}) {
      let request = axios.get(
          'https://rm6p9rl88e.execute-api.us-east-1.amazonaws.com/dev/leaderboard',
          params
          );
        return request;
    },
    save_score: function(payload, params ={}) {
      console.log(payload)
      let request = axios.post(
          'https://rm6p9rl88e.execute-api.us-east-1.amazonaws.com/dev/leaderboardPost',
          payload,
          params
          );
        return request;
    },
    impression: function(payload, params ={}) {
      console.log(payload)
      let request = axios.post(
          'https://rm6p9rl88e.execute-api.us-east-1.amazonaws.com/dev/auditPost',
          payload,
          params
          );
        return request;
    },
   
    
};

export default GameService;