import React from "react";
import { useEffect } from "react";
import ConfettiGenerator from "confetti-js";

const Confetti = props =>{
    React.useEffect(() => {
        const confettiSettings = {
          target: "my-canvas",
          max: "80",
          size: "1",
          animate: true,
          props: [
            "circle",
            "square",
            "triangle",
            "line",
            // { type: "svg", src: FalseImg, size: 55, weight: 0.3 },
          ],
          colors: [
            [165, 104, 246],
            [230, 61, 135],
            [0, 199, 228],
            [253, 214, 126],
          ],
          clock: "25",
          rotate: false,
    
          start_from_edge: false,
          respawn: true,
        };
        const confetti = new ConfettiGenerator(confettiSettings);
        confetti.render();
    
        return () => confetti.clear();
      }, []); // add the var dependencies or not

      return (
        <canvas style={{position: 'absolute'}} id="my-canvas"></canvas>
      )
}
export default Confetti;