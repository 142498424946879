import React from "react"
import Logo from "../../images/pgw-main-logo.png"
import PlainHeader from "./PlainHeader";
const About = props =>{

    return (
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                <PlainHeader></PlainHeader>
                <h1>About Us</h1>
                <h2>Political Guess Who</h2>
        <p>We are a satire game company the aims to provide a fun and age friendly gaming experience. 
            We use satire that specializes in gaining entertainment from politics. In most countries, 
            people have formed political parties to put forward their ideas, this is why we hace created PGW. 
            We are currently recruiting members to join our community who are passionate about video games and socialising with likeminded people. For any questions and concerns please email <a href="mailto:evilgeniusgamesstl@gmail.com">evilgeniusgamesstl@gmail.com</a> </p>
        </div>
            </div>
            <footer class="footer">
    <section id="footer">
		<div class="container">
			<div class="row text-center text-xs-center text-sm-left text-md-left">
				<div class="">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="/"><i class="fa fa-angle-double-right"></i>Home</a></li>
						<li><a href="/privacy-policy"><i class="fa fa-angle-double-right"></i>Privacy Policy</a></li>
						<li><a href="/about-us"><i class="fa fa-angle-double-right"></i>About Us</a></li>
						<li><a href="/blog"><i class="fa fa-angle-double-right"></i>Blog</a></li>
						<li><a href="/contact-us"><i class="fa fa-angle-double-right"></i>Contact Us</a></li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<ul class="list-unstyled list-inline social text-center">
						<li class="list-inline-item"><a href="https://www.facebook.com/Political-Guess-Who-103005904867773/"><i class="fab fa-facebook-f"></i></a></li>
		
						<li class="list-inline-item"><a href="mailto:evilgeniusgamesstl@gmail.com" target="_blank"><i class="fa fa-envelope"></i></a></li>
					</ul>
				</div>
				<hr />
		
			</div>
			<div class="row">
			
			</div>	
		
		</div>
	</section>
    </footer>
        </div>
        
    )
}

export default About;