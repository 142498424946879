import React from 'react';

import { useLocation } from 'react-router-dom'
import LogoImg from "../../images/logo.png"
import TrumpHead from "../../images/trumphead.png"
import BidenHead from "../../images/bidenhead.png"
import NewLogo from "../../images/new-logo.png"
import NewLogo2 from "../../images/pgw-main-logo.png"
import SplitText from "../core/SplitText"
function HeaderView() {
  let location = useLocation();
  console.log(location.pathname);
  return <span>Path : {location.pathname}</span>
}

const Header = props =>{
    
    return ( <header className="blog-header py-3">
    <div className="row flex-nowrap justify-content-between align-items-center">
    
      <div className="col-12 text-center title" style={{color: 'white'}}>
        <img style={{width: 340 + 'px'}} src={NewLogo2}></img>
      {/* <h1><SplitText copy="Political Guess Who" role="heading" /></h1> */}
      {/* <img
                      style={{width: 150 + 'px' }}
                      className="animate__animated animate__backInLeft animate__delay-2s"
                      src={TrumpHead}
                    ></img>
                     <img
                      style={{width: 150 + 'px' }}
                      className=" animate__animated animate__backInRight animate__delay-2s"
                      src={BidenHead}
                    ></img> */}
     <p className="tag-line animate__animated animate__fadeIn animate__delay-1s animate__slow pt-3">Can you spot a <span className="democrat">Democrat</span> from a <span className="republican">Republican</span>?</p>
      </div>
      
    </div>
  </header>)
}

export default Header;