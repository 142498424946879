import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { appState } from "../../AppState";
import useAsyncEffect from "use-async-effect";
import GameService from "../../services/service";
const LeaderboardEntry = props =>{
    const [show, setShow] = useState(true ?? false);
    const [saving, setSavings] = useState(false);
    const [stateApp, stateAppActions] = appState();
    const [name, setName] = useState('')
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const saveScore = async () => {
      setSavings(true);
        let resp = await GameService.save_score({score: stateApp.score, seconds: stateApp.time, name: name});
        setSavings(false);
        setShow(false);
     
    }
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Congrats! You made the Leaderboard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>Enter you name below to save your score. </p>
          <p><div class="form-group">
    <label for="exampleInputEmail1" class="bmd-label-floating">Name</label>
    <input type="text"  onChange={event => setName(event.target.value)}  class="form-control" id="exampleInputEmail1" />
   
  </div>
  </p>
          
         
          
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" disabled={saving} onClick={saveScore}>
              Ok
            </Button>
          </Modal.Footer>

       
        </Modal>
      </>
    );
  }
  
  export default LeaderboardEntry