import React from "react";

import useAsyncEffect from "use-async-effect";
import { useHistory } from "react-router-dom";

import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Frame from "../../images/frame.png";
import FalseImg from "../../images/false.png";
import trump from "../../images/donald-trump-icon.png";
import TrueImg from "../../images/true.png";
import { Swipeable } from "react-swipeable";
import GameService from "../../services/service";
import LevelModal from "./LevelModal";
import { BrowserView, MobileView } from "react-device-detect";
import ScoreHeader from "./ScoreHeader";
import GameControls from "./Buttons";
import Confetti from "./Confetti";
import LevelMessage from "./LevelMessage";
import { appState } from "../../AppState";
import FinishScreen from "./FinishScreen";
import axios from "axios";
import "./css/level.css";
import AdSense from "./AdSense";
const Level = (props) => {
  const [img, setImg] = useState("");
  let history = useHistory();
  const [answer, setAnswer] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [showLevelModal, setShowLevelModal] = useState(false);
  const [showGame, setShowGame] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [showFinishScreen, setShowFinishScreen] = useState(false);
  const [showLevelMessage, setShowLevelMessage] = useState(false);
  const [levels, setLevels] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [newsTitle, setNewsTitle] = useState('');
  const [newsDesc, setNewsDesc] = useState('');
  const [newsUrl, setNewsUrl] = useState('');
  const [stateApp, stateAppActions] = appState();
  const [showAd, setShowAd] = useState(false);

  useAsyncEffect(async (isMounted) => {
    const result = await GameService.get_levels();
    if (!isMounted()) return;

    //start of a new game
    setShowLevelModal(true);
    setLevels(result.data);
    setAnswer(result.data[0].answer);
    setImg(result.data[0].message);
    setShowGame(true);
    window.scrollTo(0, 0);
  }, []);
  useAsyncEffect(
    async (isMounted) => {
      //3ed72868929a4b0bb197f1a113584db7
      let resp = require('./data.json');
      
      console.log(resp.articles);
      var item = resp.articles[Math.floor(Math.random() * resp.articles.length)];
      setNewsTitle(item.title)
      setNewsDesc(item.description + ' ' + item.content)
      setNewsUrl(item.url)
      if (!isMounted()) return;
      if (stateApp.round % 5 == 0) setShowAd(true);
      if (stateApp.round == 1) setShowFinishScreen(false);
    },
    [stateApp.round]
  );

  const SubmitAnswer = (response, model) => {
    let correctAnswer = answer == response;
    let onFinalRound = false;
    let newRound = stateApp.round + 1;
    setImageLoading(true);
    if (newRound == parseInt(stateApp.totalRounds / 2)) {
      toast("Keep up the Good Work, you're Halfway There! 🥳", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (stateApp.round % 10 == 0) onFinalRound = true;

    stateAppActions.setRound(newRound);

    setCorrectAnswer(correctAnswer);

    if (correctAnswer) {
      let newScore = stateApp.score + 1;
      stateAppActions.setScore(newScore);
      stateAppActions.setConsecutiveCorrect(newScore);

      //reset consecutive wrong
      stateAppActions.setConsecutiveWrong(0);

      setShowConfetti(true);

      if (stateApp.consecutiveCorrect + 1 == 3) {
        toast("🦃 Turkey! 3 In a Row!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (stateApp.consecutiveCorrect + 1 == 5) {
        toast("🤪 Wild Bill Clinton! 5 In a Row!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      stateAppActions.setConsecutiveWrong(stateApp.consecutiveWrong + 1);
      stateAppActions.setConsecutiveCorrect(0);
      if (stateApp.lives == 0) {
        setShowLevelMessage(true);
      }
      if (stateApp.consecutiveWrong + 1 == 2) {
        toast("😴 Come on Sleepy Joe! You Can do Better!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    setTimeout(
      function () {
        if (stateApp.round == stateApp.totalRounds) setShowFinishScreen(true);
        NextLevel(model);
        setCorrectAnswer(null);
        setShowConfetti(false);
      }.bind(),
      2000
    );
  };
  const NextLevel = async (model) => {
    console.log(model);

    stateAppActions.setLevel(stateApp.level + 1);
    stateAppActions.setPicIndex(stateApp.picIndex + 1);
    let img = levels[stateApp.picIndex + 1];
    model.history.push(`/level/${stateApp.level + 1}`);
    setImg(img.message);
    setAnswer(img.answer);
    setImageLoading(false);
  
  };

  return (
    <div className="">
      {showGame && showLevelModal && <LevelModal show={true}></LevelModal>}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      ></ToastContainer>

      <Swipeable
        onSwipedLeft={() => SubmitAnswer("D", props)}
        onSwipedRight={() => SubmitAnswer("R", props)}
      >
        <AdSense>
          {showConfetti ? <Confetti></Confetti> : <></>}
          <ScoreHeader></ScoreHeader>

          {showFinishScreen ? (
            <FinishScreen></FinishScreen>
          ) : (
            <div>
               
              <div className="d-flex flex-column desktop-image">
              
                <img className="img-level" src={img}></img>
         
                {correctAnswer == null ? (
                  <></>
                ) : correctAnswer ? (
                  <img
                    style={{ position: "absolute" }}
                    className="false-image "
                    src={TrueImg}
                  ></img>
                ) : (
                  <img
                    style={{ position: "absolute" }}
                    className="false-image animate__animated animate__backOutLeft animate__delay-1s"
                    src={FalseImg}
                  ></img>
                )}
              </div>
          
              <GameControls
                active={imageLoading}
                SubmitAnswer={SubmitAnswer}
                model={props}
              ></GameControls>
            </div>
          )}
        </AdSense>
      </Swipeable>
    </div>
  );
};

export default Level;
