import React from "react";
import RepublicanLogo from "../../images/republican-icon.png";
import DemocraticLogo from "../../images/democratic-logo.png";
import { appState } from "../../AppState";
const GaneControls = (props) => {
  const [stateApp, stateAppActions] = appState();
  return (

    <div className="fixed-bottom">
       <div className="d-flex flex-row align-items-end justify-content-between">
           <div className="flex-column p-3">
          <button
           disabled={props.active}
            type="button"
            className="btn btn-danger bmd-btn-fab"
            style={{ backgroundColor: "white" }}
            onClick={() => props.SubmitAnswer("D", props.model)}
          >
            <img src={DemocraticLogo} style={{ width: 55 + "px" }}></img>
          </button>
          <p className="text-center" style={{  fontVariant: "all-small-caps" }}>
            Democrat
          </p>

          </div>
          <div className="flex-column p-3">
          <button
            disabled={props.active}
            type="button"
            className="btn btn-danger bmd-btn-fab pull-right"
            style={{ backgroundColor: "white" }}
            onClick={() => props.SubmitAnswer("R", props.model)}
          >
            <img src={RepublicanLogo} style={{ width: 55 + "px"}}></img>
          </button>
          <p className="text-center" style={{  fontVariant: "all-small-caps" }}>
            Republican
          </p>
          </div>
          </div>
          </div>

  );
};
export default GaneControls;
