import React from "react"
import PlainHeader from "../PlainHeader"


const Article14 = props =>{


    return (
        <div><PlainHeader></PlainHeader><h1>Trial of Indian citizen for spying on Sikh, Kashmiri community begins in Germany | 30 Pakistanis deported living illegally in Turkey</h1>
        <p>The trial of an Indian national accused of spying on the Sikh and Kashmiri communities in Germany for the New Delhi Secret Service has begun in Frankfurt. According to media reports, the suspect has been identified as 54-year-old Blair SK. He has been accused of working for India's foreign intelligence agency, the Research and Analysis Wing (RAW), at least since January 2015. I obtained the information and passed it on to those who witnessed such cases at the Indian Consulate in Frankfurt. A total of 10 hearings have been scheduled in the Regional High Court and the last hearing of the case will be held on October 29. A Frankfurt court last December convicted an Indian couple of spying for the same communities. The husband was sentenced to 18 months in prison and his wife for working as a foreign intelligence agent. A fine of 180 days was imposed for aiding and abetting. 1947 between India and Pakistan There has been a Kashmir dispute since independence, with nuclear rivals fighting two of the three wars for the region. That's between 10,000 and 20,000, and it's the highest in any region of Europe. Thirty Pakistanis residing illegally in Turkey have been deported. The deported Pakistanis were sent to Islamabad on Turkish Airlines flight TK710, handed over to FIA immigration officials at Islamabad International Airport. FIA Human Trafficking Cell Transferred to Islamabad for Investigation on Illegal Entry into Turkey.</p></div>
    )
}

export default Article14