import React, { useEffect } from "react";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useHistory } from "react-router-dom";
import { appState } from "../AppState";
import GameService from "../services/service";
const LoginComponent = (props) => {
  let history = useHistory();
  const [stateApp, stateAppActions] = appState();
  useEffect(() => {
    loadFbLoginApi();
  }, []);
  const responseFacebook = async (response) => {
    console.log(response);
    props.cookies.set("fb", response, { path: "/" });
    await GameService.impression({
      type: "fb",
      user: JSON.stringify(response),
      event: "login",
      data: "{}",
    });
    stateAppActions.setFacebook(response);
    stateAppActions.setGameStart(true);

    history.push("/level/1");
  };
  const loadFbLoginApi = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "290889828913599",
        cookie: true, // enable cookies to allow the server to access
        // the session
        xfbml: true, // parse social plugins on this page
        version: "v2.5", // use version 2.1
      });
    };

    console.log("Loading fb api");
    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const testAPI = () => {
    console.log("Welcome!  Fetching your information.... ");
    window.FB.api("/me", { fields: "name,email" }, function (response) {
      responseFacebook(response);
      console.log(response);
    });
  };

  const statusChangeCallback = (response) => {
    console.log("statusChangeCallback");
    console.log(response);
    if (response.status === "connected") {
      testAPI();
    } else if (response.status === "not_authorized") {
      console.log("Please log into this app.");
    } else {
      console.log("Please log into this facebook.");
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(
      function (response) {
        statusChangeCallback(response);
      }.bind(this)
    );
  };

  const handleFBLogin = () => {
    window.FB.login(
      function (response) {
        // handle the response
        console.log(response);
        checkLoginState();
      },
      {
        scope: "email",
        return_scopes: true,
      }
    );
  };

  return (
    <div>
      <FacebookLoginButton onClick={() => handleFBLogin()} />
    </div>
  );
};

export default LoginComponent;
