import React, { useEffect } from "react";
import { appState } from "../AppState";
import Cookies from "universal-cookie";
import { Redirect } from "react-router-dom";
const GameProtector = (props) => {
  const [stateApp, stateAppActions] = appState();
 

  const hasLoggedin = () => {
    return true;
    if (stateApp.facebookInfo != null) return true;

    if (stateApp.email != null) return true;

    return false;
  };



  return    stateApp.gameStarted && hasLoggedin() ? (
    props.children
  ) : (
    <Redirect to="/login" />
  );
};

export default GameProtector;
