import React, { useState } from "react";
import "./css/Leaderboard.css";
import useAsyncEffect from "use-async-effect";
import { Swipeable } from "react-swipeable";
import GameService from "../../services/service";
import NewLogo2 from "../../images/pgw-main-logo.png"
import { useHistory } from "react-router-dom";
import { appState } from "../../AppState";
import moment from "moment"
import momentDurationFormatSetup  from "moment-duration-format";
const Leaderboard = (props) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [stateApp, stateAppActions] = appState();
  const emptySlots = new Array(4).fill(null);
  let history = useHistory();

  function startGame() {
      stateAppActions.setGameStart(true);
    history.push("/level/1");
  }
  useAsyncEffect(async (isMounted) => {
    let result = [];
    if(stateApp.leaderboard.length == 0){
    let resp = await GameService.get_leaderboard();
    result = resp.data
    }else{
      result = stateApp.leaderboard;
    }
    
    
    if (!isMounted()) return;

    //start of a new game
    setLeaderboard(result);
  }, []);
  return (
      <>
    
    <div className="container">
    <div className="row">
        <div className="col-lg-4">
        <div className="text-center">
    <a href="/start"><img style={{width: 240 + 'px'}} src={NewLogo2}></img></a>
    </div>
        </div>
        </div>
      <div className="row">
        <div className="col-lg-4">
        <div class="leaderboard">
      <h1>Top 5 Players</h1>
     
      <ol>
        {leaderboard.map((e) => {
          return (
            <li>
              <mark>{e.name}
              <small className="pb-2">{moment.duration(parseFloat(e.seconds), "seconds").format("m [minutes], s [seconds]")}</small></mark>
              <small><strong>{e.score} Correct</strong></small>
            </li>
          );
        })}
       
      </ol>
      
    </div>
        </div>
      </div>

                </div>
    </>
  );
};

export default Leaderboard;
