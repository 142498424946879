import React from "react";
import PlainHeader from "./PlainHeader"
import data from "./blogs.json"
const Blog = (props) => {
  console.log(data)
  return (
    <div className="container"><PlainHeader></PlainHeader>
      <div className="row">
        {data.map((e) => {
          return(
            <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">{e.title}</h5>
              <p class="card-text">
             {e.description}</p>
              <a href={e.link} class="btn btn-primary">
                Read More
              </a>
            </div>
          </div>
        </div>
          )
        })}
        <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Trump embraces QAnon conspiracy because 'they like me'</h5>
              <p class="card-text">
              (CNN)After skirting the issue for weeks, President Donald Trump offered an embrace Wednesday of the fringe internet phenomenon QAnon, praising its followers for supporting him and shrugging off its outlandish conspiracies.

His comments reflected the highest-profile endorsement to date of the group, which has infiltrated Republican circles even as party leaders attempt to distance themselves.
"I don't know much about the movement other than I understand they like me very much, which I appreciate," Trump said in the White House briefing room.
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Trump and Biden both claim the other wants to cut Social Security. Here are the facts</h5>
              <p class="card-text">
              On August 8, Trump signed an executive action to temporarily suspend the payroll tax for many employees. It also leaves open the possibility of forgiving the deferred tax down the road.
Critics argue that this would essentially defund Social Security and Medicare because they are both primarily financed by the tax. Others say the temporary pause won't have a significant effect on Social Security benefits because the President alone can't reduce payroll taxes, which would require action from Congress.
When asked about the effect this action would have on Social Security trust funds, White House counselor Kellyanne Conway told reporters in a press gaggle on August 11 the President is still committed to protecting entitlements but did not outline how he would do so. Conway also said Trump was more concerned with people who are currently trying to make ends meet, rather than the longer-term aims of Social Security.
As CNN has reported, many business leaders have argued against Trump's push to defer payroll taxes, arguing that workers could end up owing more in taxes if implemented.
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">A growing sense of panic' with no fresh federal relief in sight</h5>
              <p class="card-text">
              Washington (CNN)It's been four months since most of the federal government's $1,200 stimulus checks went out. The $600 a week federal boost to unemployment benefits expired three weeks ago, along with a moratorium on evictions. Many small businesses have already spent the money from their federal emergency loans.

While President Donald Trump has attempted to fill in some of the gap via executive action, discussions in Congress over reinstating any of those programs or creating new ones have stalled, with no progress expected until September at the earliest -- even as the economy continues to stagger.
Since the end of July, about a dozen more people are showing up each day for a free bagged lunch at a center for the homeless in northwest Washington, DC, where tents dot parks and street corners around the neighborhood. More than a third are new to the center, Charlie's Place, which is also handing out clothes and groceries.
"People have lost their jobs. They're no longer getting stimulus money. They just couldn't hold on," said Reggie Cox, director of the center.
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Transcript: Kamala Harris' DNC speech</h5>
              <p class="card-text">
              This week marks the 100th anniversary of the passage of the 19th amendment. And we celebrate the women who fought for that right.
Yet so many of the Black women who helped secure that victory were still prohibited from voting, long after its ratification.
But they were undeterred.
Without fanfare or recognition, they organized, testified, rallied, marched, and fought—not just for their vote, but for a seat at the table. These women and the generations that followed worked to make democracy and opportunity real in the lives of all of us who followed.
They paved the way for the trailblazing leadership of Barack Obama and Hillary Clinton.
And these women inspired us to pick up the torch, and fight on.
Women like Mary Church Terrell and Mary McCleod Bethune. Fannie Lou Hamer and Diane Nash. Constance Baker Motley and Shirley Chisholm.
We're not often taught their stories. But as Americans, we all stand on their shoulders.
There's another woman, whose name isn't known, whose story isn't shared. Another woman whose shoulders I stand on. And that's my mother—Shyamala Gopalan Harris.
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Transcript: Barack Obama's DNC speech</h5>
              <p class="card-text">
              Good evening, everybody. As you've seen by now, this isn't a normal convention. It's not a normal time. So tonight, I want to talk as plainly as I can about the stakes in this election. Because what we do these next 76 days will echo through generations to come.
I'm in Philadelphia, where our Constitution was drafted and signed. It wasn't a perfect document. It allowed for the inhumanity of slavery and failed to guarantee women -- and even men who didn't own property -- the right to participate in the political process. But embedded in this document was a North Star that would guide future generations; a system of representative government -- a democracy -- through which we could better realize our highest ideals. Through civil war and bitter struggles, we improved this Constitution to include the voices of those who'd once been left out. And gradually, we made this country more just, more equal, and more free.
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 pb-2">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Trump 'cancels' Goodyear tires as he campaigns against 'cancel culture'</h5>
              <p class="card-text">
              (CNN)President Donald Trump is calling on his followers to not buy Goodyear tires and threatening to remove them from his custom presidential limousine, despite previously railing against "cancel culture," after an employee posted a viral photo of a company policy banning "Make America Great Again" and other political attire in the workplace.

"Don't buy GOODYEAR TIRES - They announced a BAN ON MAGA HATS. Get better tires for far less! (This is what the Radical Left Democrats do. Two can play the same game, and we have to start playing it now!)," he tweeted Wednesday morning.
The tweet came in response to an employee who posted a photo, obtained by CNN affiliate WIBW, from a Topeka, Kansas, Goodyear plant that showed a slide during a training that "Black Lives Matter" and LBGT pride apparel were "acceptable" and "Blue Lives Matter," "All Lives Matter," "MAGA Attire," and other political material were "unacceptable."
Goodyear issued a statement following the President's tweet stating "the visual in question was not created or distributed by Goodyear corporate," but that it asks its associates to "refrain from workplace expressions in support of political campaigning for any candidate or political party, as well as similar forms of advocacy that fall outside the scope of racial justice and equity issues."
              </p>
              <a href="#" class="btn btn-primary">
                Go somewhere
              </a>
            </div>
          </div>
        </div>
        
      </div>
      <footer class="footer">
    <section id="footer">
		<div class="container">
			<div class="row text-center text-xs-center text-sm-left text-md-left">
				<div class="">
					<h5>Quick links</h5>
					<ul class="list-unstyled quick-links">
						<li><a href="/"><i class="fa fa-angle-double-right"></i>Home</a></li>
						<li><a href="/privacy-policy"><i class="fa fa-angle-double-right"></i>Privacy Policy</a></li>
						<li><a href="/about-us"><i class="fa fa-angle-double-right"></i>About Us</a></li>
						<li><a href="/blog"><i class="fa fa-angle-double-right"></i>Blog</a></li>
						<li><a href="/contact-us"><i class="fa fa-angle-double-right"></i>Contact Us</a></li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-4 col-md-4">
					<ul class="list-unstyled list-inline social text-center">
						<li class="list-inline-item"><a href="https://www.facebook.com/Political-Guess-Who-103005904867773/"><i class="fab fa-facebook-f"></i></a></li>
		
						<li class="list-inline-item"><a href="mailto:evilgeniusgamesstl@gmail.com" target="_blank"><i class="fa fa-envelope"></i></a></li>
					</ul>
				</div>
				<hr />
		
			</div>
			<div class="row">
			
			</div>	
		
		</div>
	</section>
    </footer>
    </div>
  );
};

export default Blog;
