import React from "react"
import {  useState } from "react";
import useAsyncEffect from "use-async-effect";

import { appState } from "../../AppState";


const AdSense = props =>{
    const [showAd, setShowAd] = useState(false)
    const [stateApp, stateAppActions] = appState();
    useAsyncEffect(async (isMounted) => {
  
        if (!isMounted()) return;
        setTimeout(function(){  
           // if(stateApp.round % 5 == 0) setShowAd(true); 
        }, 
            1000);
       
      }, [stateApp.round]);
    
    return (

        showAd ? 
        <div className="h-100">
            <p>Here is our ad</p>
        </div>
        : props.children

    )


}

export default AdSense